;(function ($) {

    function windowInnerWidth(){
        return (window.innerWidth || document.documentElement.clientWidth || 0);
    }
    function windowInnerHeight(){
        return (window.innerHeight || document.documentElement.clientHeight || 0);
    }

    var touchdevice = false;

    if(Modernizr.touchevents){
        $('body').addClass('touchdevice');
        touchdevice = true;
    }else{
        $('body').addClass('notTouchdevice');
    }


    ////-----------------------------------------------------------------------------------------
    //// lightcase
    ////-----------------------------------------------------------------------------------------
    $('[data-rel^=lightcase]').lightcase();


    ////-----------------------------------------------------------------------------------------
    //// slick
    ////-----------------------------------------------------------------------------------------
    $('#slide-main').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true
    });
    $('#slide-banner').slick({
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    ////-----------------------------------------------------------------------------------------
    ////重要なお知らせ
    ////-----------------------------------------------------------------------------------------
    $('#home-topNews-link').on('click',function(){
        $('#home-topNews-hidden').slideToggle(200).toggleClass('newsOpen');
        if($('#home-topNews-hidden').hasClass('newsOpen')){
            $(this).text('閉じる');
        }else{
            $(this).text('このほかの重要なお知らせを見る');
        }
    });

    $('.home-newsBox-contents').on('load', function(){
        $('.home-newsBox-contents').contents().find('head').append('<link rel="stylesheet" href="css/style.min.css">');
    });
    // $('[data-fz="acblty-fz_l"]').on('click',function(){
    //     $('.home-newsBox-contents').contents().find('html').removeClass('acblty-fz_m');
    //     $('.home-newsBox-contents').contents().find('html').removeClass('acblty-fz_s');
    //     $('.home-newsBox-contents').contents().find('html').addClass('acblty-fz_l');
    // });
    // $('[data-fz="acblty-fz_m"]').on('click',function(){
    //     $('.home-newsBox-contents').contents().find('html').removeClass('acblty-fz_l');
    //     $('.home-newsBox-contents').contents().find('html').removeClass('acblty-fz_s');
    //     $('.home-newsBox-contents').contents().find('html').addClass('acblty-fz_m');
    // });
    // $('[data-fz="acblty-fz_s"]').on('click',function(){
    //     $('.home-newsBox-contents').contents().find('html').removeClass('acblty-fz_l');
    //     $('.home-newsBox-contents').contents().find('html').removeClass('acblty-fz_m');
    //     $('.home-newsBox-contents').contents().find('html').addClass('acblty-fz_s');
    // });
    ////-----------------------------------------------------------------------------------------
    ////tab
    ////-----------------------------------------------------------------------------------------

    $('.tab-nav a').on('click',function(){
        var target = $(this).attr('href');
        var tabNavActive = $(this).parent().parent().find('.active');
        var tabConttentsActive = $(this).parent().parent().parent().next().find('.active');
        $(tabConttentsActive).removeClass('active');
        $(tabNavActive).removeClass('active');
        $(this).parent().addClass('active');
        $(target).addClass('active');
        return false;
    });

    $('#gHeader-spBtn').on('click',function(){
        $(this).toggleClass('close');
        $(this).next().toggleClass('menuOpen');
    });

    ////-----------------------------------------------------------------------------------------
    //// フォントサイズ変更
    ////-----------------------------------------------------------------------------------------

    //ボタンを変数に格納
    var $fzBtn = $('#gHeader-fontSizeChange button');

    //クッキー用オブジェクト
    var cdata = {};

    //クッキー読み込み（存在していればフォントサイズ変更）
    if(zcookies.get( 'acblty' )){
        cdata = zcookies.get( 'acblty' );
        acbltySet(cdata.fz,false);
    }

    //ボタンイベント
    $fzBtn.on('click',function(){
        var fz = $(this).data('fz');
        console.log('fz',fz);
        acbltySet(fz,true);
    });

    //フォントサイズ変更メイン処理
    function acbltySet(fz,save){
        if(fz && fz !== ""){

            //htmlタグに「acblty-fz_」ではじまるクラスがあれば削除、その後クラス名追加
            var regexp = new RegExp('\\acblty-fz_\\S+', 'g');
            $('html').removeClass(function(index, className) {
                return (className.match(regexp) || []).join(' ');
            }).addClass(fz);

            //iframe内のhtmlタグに「acblty-fz_」ではじまるクラスがあれば削除、その後クラス名追加
            $('.home-newsBox-contents').contents().find('html').removeClass(function(index, className) {
                return (className.match(regexp) || []).join(' ');
            }).addClass(fz);

            //すべてのボタンからactiveクラスを削除し、該当するボタンに追加
            $fzBtn.removeClass('active').filter('[data-fz='+fz+']').addClass('active');

            //クッキー保存
            if(save){
                cdata.fz = fz;
                cookieSet();
            }
        }
    }

    //クッキー設定
    function cookieSet(){
        console.log('cdata',cdata);
        zcookies.set( 'acblty', cdata, { expires: 60 * 60 * 24 * 7,path:"/" } );//60秒✕60分✕24時間✕7日 = 1週間保存
    }
})(jQuery);
